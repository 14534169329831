import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollSmoother } from 'gsap/ScrollSmoother'

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger, ScrollSmoother)
}

const StyledProjectsContainer = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.black};
  color: ${(props) => props.theme.colors.white};
  font-family: 'Roboto', sans-serif;

  svg {
    width: 204px;
    height: 204px;
    z-index: 3;
    fill: ${(props) => props.theme.colors.white};
  }

  h2 {
    font-family: ${(props) => props.theme.fonts.grotta};
    font-size: clamp(2em, 4vw, 5em);
    letter-spacing: 0.5rem;
    margin: 0;
    line-height: 1;
  }
  p {
    font-family: ${(props) => props.theme.fonts.body};
    font-size: 1em;
    font-weight: 300;
    line-height: 1.2em;
    letter-spacing: 0.2em;
  }

  .wrapper {
    position: relative;
    max-width: 1024px;
    margin: 0 auto;
    padding: 8em 2em;
  }

  .video-bg {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .video-bg svg {
    position: absolute;
  }

  .image-parent {
    height: 100%;
    overflow: hidden;
  }

  .image-child {
    height: 120%;
  }

  img {
    display: block;
    max-width: 100%;
    opacity: 0.6;
  }

  article {
    display: grid;
  }

  .reverse-overlapping {
    z-index: 2;
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    article {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
    }

    .overlapping,
    .overlapping-video-bg,
    .reverse-overlapping,
    .reverse-overlapping-video-bg {
      grid-row: 1 / 2;
    }

    .overlapping {
      z-index: 2;
      grid-column: 1 / 4;
      align-self: end;
    }

    .overlapping-video-bg {
      grid-column: 2 / -1;
    }

    .reverse-overlapping {
      grid-column: 4 / -1;
      align-self: center;
    }

    .reverse-overlapping-video-bg {
      grid-column: 1 / 5;
    }
  }
  @media (prefers-reduced-motion) {
    .animation {
      animation-name: dissolve;
    }
  }
`

const ScrollSmootherProject = ({ projects }) => {
  useEffect(() => {
    // create the smooth scroller FIRST!
    const smoother = ScrollSmoother.create({
      smooth: 2, // seconds it takes to "catch up" to native scroll position
      effects: true, // look for data-speed and data-lag attributes on elements and animate accordingly
      smoothTouch: 0.1,
    })

    gsap.to('.SVGref', {
      rotate: () => -360,
      ease: 'none',
      scrollTrigger: {
        start: 0,
        end: 'max',
        scrub: 1,
        // markers: true,
      },
    })

    return () => {
      smoother.kill()
    }
  }, [])

  return (
    <StyledProjectsContainer>
      <section className="wrapper">
        <article>
          <header className="overlapping" data-speed="1.2">
            <h2>
              Jean <br />
              Emmanuel <br />
              Rode
            </h2>
            <p>
              Jerky pastrami strip steak pork chuck. Biltong boudin burgdoggen
              shankle, short ribs short loin drumstick corned beef rump ribeye
              filet mignon pork chop.{' '}
            </p>
          </header>
          <div className="video-bg overlapping-video-bg">
            <div className="image-parent">
              <div className="image-child">
                <img
                  data-speed="auto"
                  src={projects[0].node.data.horizontalimage.fluid.src}
                  alt=""
                />
              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 440 440"
              className="SVGref"
              data-speed="1.5"
            >
              <g data-name="Group 2">
                <path
                  d="M440,211.9l-0.6-10.3l-208,15.9l200.9-56.3l-2.9-9.8L230.6,215l182.5-101.2l-5.1-8.9L229.3,212.6L383.6,72l-7-7.5
	L227.4,210.7L345.2,38.2l-8.5-5.6L225.2,209.2l74.9-195l-9.6-3.5l-67.8,197.6l28-207.1L240.5,0L220,208L199.5,0l-10.2,1.2l28,207.1
	L149.5,10.6l-9.6,3.5l74.9,195L103.3,32.6l-8.5,5.6l117.8,172.5L63.4,64.6l-7,7.5l154.3,140.6L32,104.9l-5.1,8.9L209.4,215
	L10.6,151.4l-2.9,9.8l200.9,56.3l-208-15.9L0,211.9l208.5,8.4L2.4,252.8l1.8,10.1l204.7-40l-193,79.3l4,9.4L210,225.3L40.4,347.1
	l6.1,8.2l165.1-127.8L74.6,385.1l7.8,6.6l131.2-162.5l-97,185l9.2,4.6L216,230.4l-51.8,202.4l10,2.4l44.4-204.1L214.9,440h10.2
	l-3.8-208.9l44.4,204.2l10-2.4L224,230.5l90.2,188.4l9.2-4.6l-97-185l131.2,162.5l7.8-6.6L228.4,227.5l165.1,127.8l6.1-8.2
	L230,225.3l190,86.2l4.1-9.4l-193-79.3l204.7,40l1.8-10.1l-206.1-32.6L440,211.9z"
                />
              </g>
            </svg>
          </div>
        </article>
      </section>

      <section className="wrapper">
        <article>
          <header className="reverse-overlapping" data-speed="1.2">
            <h2>Aub'Orescence</h2>
            <p>
              Bacon ipsum dolor amet short ribs flank tongue beef porchetta
              t-bone. Burgdoggen porchetta shoulder, beef ribs kielbasa pork
              belly drumstick.
            </p>
          </header>
          <div className="video-bg reverse-overlapping-video-bg">
            <div className="image-parent">
              <div className="image-child">
                <img
                  data-speed="auto"
                  src={projects[1].node.data.horizontalimage.fluid.src}
                  alt=""
                />
              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 440 440"
              className="SVGref"
              data-speed="1.5"
            >
              <g data-name="Group 2">
                <path
                  d="M440,211.9l-0.6-10.3l-208,15.9l200.9-56.3l-2.9-9.8L230.6,215l182.5-101.2l-5.1-8.9L229.3,212.6L383.6,72l-7-7.5
	L227.4,210.7L345.2,38.2l-8.5-5.6L225.2,209.2l74.9-195l-9.6-3.5l-67.8,197.6l28-207.1L240.5,0L220,208L199.5,0l-10.2,1.2l28,207.1
	L149.5,10.6l-9.6,3.5l74.9,195L103.3,32.6l-8.5,5.6l117.8,172.5L63.4,64.6l-7,7.5l154.3,140.6L32,104.9l-5.1,8.9L209.4,215
	L10.6,151.4l-2.9,9.8l200.9,56.3l-208-15.9L0,211.9l208.5,8.4L2.4,252.8l1.8,10.1l204.7-40l-193,79.3l4,9.4L210,225.3L40.4,347.1
	l6.1,8.2l165.1-127.8L74.6,385.1l7.8,6.6l131.2-162.5l-97,185l9.2,4.6L216,230.4l-51.8,202.4l10,2.4l44.4-204.1L214.9,440h10.2
	l-3.8-208.9l44.4,204.2l10-2.4L224,230.5l90.2,188.4l9.2-4.6l-97-185l131.2,162.5l7.8-6.6L228.4,227.5l165.1,127.8l6.1-8.2
	L230,225.3l190,86.2l4.1-9.4l-193-79.3l204.7,40l1.8-10.1l-206.1-32.6L440,211.9z"
                />
              </g>
            </svg>
          </div>
        </article>
      </section>
    </StyledProjectsContainer>
  )
}

export default ScrollSmootherProject
