import React, { useRef, Suspense } from 'react'
import styled from '@emotion/styled'
import { gsap } from 'gsap'
import { SplitText } from 'gsap/SplitText'

import Scene from './Scene'

import topOne from '../../images/test-Y30.jpeg'
import topTwo from '../../images/test-Y40.jpeg'

gsap.registerPlugin(SplitText)

const StyledHeroWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 120px 0 0;
  padding: 0;
  width: 100vw;
  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    margin: 0;
    min-height: 100vh;
  }
`

const StyledHeroTextOne = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  h2 {
    position: relative;
    perspective: 200px;
    font-size: 13vw;
    letter-spacing: 4px;
    margin: 0;
    text-align: left;
    text-transform: uppercase;
    z-index: 10;
    color: #000;
    font-family: ${(props) => props.theme.fonts.grotta};
    font-size: 14.5vw;
    font-weight: 400;
    line-height: 1;
    @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
      font-size: 11vw;
    }
  }
  .hero__image {
    margin-left: 8px;
    max-width: 240px;
    flex: 1 0;

    img {
      display: none;
      // visibility: hidden;
      height: auto;
      max-width: 100%;
      vertical-align: middle;
    }
  }
`

const StyledHeroTextTwo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
  transform: translateX(-40px);
  h2 {
    position: relative;
    perspective: 200px;
    font-size: 14.5vw;
    letter-spacing: -2px;
    margin: 0;
    text-align: left;
    text-transform: uppercase;
    z-index: 10;
    color: #000;
    font-family: ${(props) => props.theme.fonts.body};
    font-size: 14.5vw;
    font-weight: 400;
    line-height: 1;
    @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
      font-size: 12vw;
    }
  }
  .hero__description-wrapper {
    display: block;
    font-size: 14px;
    line-height: 1.25;
    span {
      display: inline-block;
      font-size: 22px;
      padding: 8px 16px;
      text-transform: uppercase;
      transform: translate3d(16px, 64px, 0);
      font-family: ${(props) => props.theme.fonts.body};
      font-weight: 300;
    }
  }
`

const StyledHeroTextThree = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  h2 {
    margin-top: 16px;
    position: relative;
    perspective: 200px;
    font-size: 13vw;
    letter-spacing: 4px;
    margin: 0;
    text-align: left;
    text-transform: uppercase;
    z-index: 10;
    color: #000;
    font-family: ${(props) => props.theme.fonts.grotta};
    font-size: 14.5vw;
    font-weight: 400;
    line-height: 1;
    @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
      font-size: 11vw;
    }
  }
  .hero__image {
    margin-right: 8px;
    max-width: 200px;
    flex: 1 0;
    img {
      display: none;
      // visibility: hidden;
      height: auto;
      max-width: 100%;
      vertical-align: middle;
    }
  }
`

const IndexPage = () => {
  return (
    <>
      <StyledHeroWrapper id="home-top">
        <StyledHeroTextOne>
          <h2>Motion</h2>
          <div className="hero__image" id="home-top__img-r">
            <Scene image={topTwo} />
            <img
              // width="720"
              // height="411"
              src={topOne}
              alt="Top 1"
            />
          </div>
        </StyledHeroTextOne>
        <StyledHeroTextTwo>
          <h2>DESIGNER</h2>
          <div className="hero__description-wrapper">
            <span>AND</span>
          </div>
        </StyledHeroTextTwo>
        <StyledHeroTextThree>
          <div className="hero__image" id="home-top__img-l">
            <Scene image={topOne} />
            <img
              // width="720"
              // height="411"
              src={topTwo}
              alt="Top 2"
            />
          </div>
          <h2 data-scroll-repeat data-scroll data-scroll-class="in-view">
            DEVELOPER
          </h2>
        </StyledHeroTextThree>
      </StyledHeroWrapper>
    </>
  )
}

export default IndexPage
