import React, { useLayoutEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

const StyledContactTitle = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 100%;
  .contact__title--container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-top: 0;
    .contact__title {
      flex: 0 0 auto;
      width: 100%;
      @media screen and (min-width: ${(props) =>
          props.theme.responsive.medium}) {
        width: 83.33333333%;
      }
      h3 {
        position: relative;
        display: flex;
        flex-direction: column;
        font-size: 16vw;
        letter-spacing: 4px;
        margin: 160px 0 80px 0;
        text-align: left;
        text-transform: uppercase;
        z-index: 10;
        color: ${(props) => props.theme.colors.black};
        font-family: ${(props) => props.theme.fonts.grotta};
        line-height: 1;
        @media screen and (min-width: ${(props) =>
            props.theme.responsive.medium}) {
          font-size: 14.5vw;
        }
        span {
          will-change: transform;
          align-self: flex-start;
        }
        span:nth-of-type(2) {
          align-self: flex-end;
        }
      }
    }
  }
`

const StyledContactMessage = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 12rem;
  width: 100%;
  .contact__message--container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-top: 0;
    .contact__message {
      flex-shrink: 0;
      margin-top: 0;
      max-width: 100%;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      width: 100%;
      @media screen and (min-width: ${(props) =>
          props.theme.responsive.medium}) {
        max-width: 800px;
      }
      .contact__message--text {
        will-change: transform;
        margin: 0;
        padding: 0 4px;
        text-transform: uppercase;
        font-family: ${(props) => props.theme.fonts.body};
        font-size: 24px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 32px;
        @media screen and (min-width: ${(props) =>
            props.theme.responsive.medium}) {
          font-size: 32px;
          letter-spacing: -1px;
          line-height: 48px;
        }
        .contact__message--email {
          display: inline-block;
          u {
            display: inline-block;
            position: relative;
            text-decoration: none;
            &:before {
              background: ${(props) => props.theme.colors.black};
              bottom: 2px;
              content: '';
              height: 2px;
              left: 0;
              position: absolute;
              width: 100%;
            }
            a {
              text-decoration: none;
              color: ${(props) => props.theme.colors.black};
            }
          }
        }
      }
    }
  }
`

const GetInTouch = () => {
  const contactTitleTL = useRef()
  const contactMessageTL = useRef()
  const titleRef = useRef()
  const messageRef = useRef()

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      let aniP = {
        opacity: 0,
        duration: 1.25,
        y: 250,
        scaleY: 2,
        stagger: 0.15,
        transformOrigin: '0% 0% 0%',
        rotation: 15,
        rotationX: -25,
        ease: 'power3.inOut',
        clearProps: true,
        delay: 0.25,
      }

      contactTitleTL.current = gsap
        .timeline({
          scrollTrigger: {
            trigger: titleRef.current,
            start: 'top bottom',
            // markers: true,
          },
        })
        .set('.home__contact-title', {
          perspective: 200,
        })
        .from('.home__contact-title span', aniP)

      contactMessageTL.current = gsap
        .timeline({
          scrollTrigger: {
            trigger: messageRef.current,
            start: 'top bottom',
            // markers: true,
          },
        })
        .set('.contact__message', {
          perspective: 200,
        })
        .from('.contact__message--text', aniP)

      var hover = false
      document.querySelector('.contact__message--email').addEventListener(
        'mouseover',
        function (event) {
          if (!hover) {
            gsap.to(
              '.contact__message--text span:not(.contact__message--email)',
              {
                opacity: 0.25,
                duration: 0.5,
                ease: 'power3.inOut',
              }
            )
          }
          hover = true
        },
        false
      )
      document.querySelector('.contact__message--email').addEventListener(
        'mouseleave',
        function (event) {
          gsap.to(
            '.contact__message--text span:not(.contact__message--email)',
            {
              opacity: 1,
              duration: 0.5,
              ease: 'power3.inOut',
            }
          )

          hover = false
        },
        false
      )
    }
  }, [])

  return (
    <>
      <StyledContactTitle ref={titleRef}>
        <div className="contact__title--container">
          <div className="contact__title">
            <h3 className="home__contact-title">
              <span>GET IN</span>
              <span>TOUCH</span>
            </h3>
          </div>
        </div>
      </StyledContactTitle>
      <StyledContactMessage ref={messageRef}>
        <div className="contact__message--container">
          <div className="contact__message">
            <div className="contact__message--text">
              <span>Let's create your brand in motion, drop me an </span>
              <span className="contact__message--email">
                <u>
                  <a href="mailto:hello@nowmo.design">email</a>
                </u>
              </span>
            </div>
          </div>
        </div>
      </StyledContactMessage>
    </>
  )
}

export default GetInTouch
