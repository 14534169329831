import React, { useRef, useLayoutEffect } from 'react'
import styled from '@emotion/styled'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { gsap } from 'gsap'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
import { SplitText } from 'gsap/SplitText'

const StyledSliderHeroContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background: ${(props) => props.theme.colors.white};
`

const StyledBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${(props) => props.theme.colors.black};
  z-index: 200;
`

const StyledImageContainer = styled.div`
  --mask-top: 0;
  --mask-right: 0;
  --mask-bottom: 0;
  --mask-left: 0;

  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${(props) => props.theme.colors.white};

  .image-container {
    // clip-path: inset(
    //   calc(var(--mask-top) * 1%) calc(var(--mask-right) * 1%)
    //     calc(var(--mask-bottom) * 1%) calc(var(--mask-left) * 1%)
    // );
    // clip-path: inset(10% 15% 10% 50% round 25vw 25vw 25.01vw 25.01vw);
    clip-path: inset(20% 40% 20.01% 40.01% round 25vw 25vw 25.01vw 25.01vw);

    width: 100%;
    height: 100%;

    .gatsby-image-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
`

const StyledLogoContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  mix-blend-mode: difference;

  svg {
    padding: 4vw;
    width: 100%;
  }

  .home-hero-title {
    h3 {
      font-family: ${(props) => props.theme.fonts.grotta};
      color: ${(props) => props.theme.colors.white};
      font-size: 5.3vw;
      line-height: 5.3vw;
      @media screen and (min-width: ${(props) =>
          props.theme.responsive.medium}) {
        font-size: 3.3vw;
        line-height: 3.3vw;
      }
    }
  }
`

const SliderHero = ({ homepage }) => {
  const mainImage = getImage(homepage.data.cover.gatsbyImageData)
  const imageContainerRef = useRef()
  const imageClipRef = useRef()

  const tl = useRef()
  const bgRef = useRef()
  const SVGRef = useRef()
  const parentRectRef = useRef()
  const rect1Ref = useRef()
  const rect2Ref = useRef()
  const rect3Ref = useRef()
  const rect4Ref = useRef()
  const rect5Ref = useRef()
  const rect6Ref = useRef()
  const rect7Ref = useRef()
  const rect8Ref = useRef()
  const rect9Ref = useRef()
  const rect10Ref = useRef()
  const homeHeroTitleRef = useRef()
  const circle1Ref = useRef()
  const circle2Ref = useRef()
  const titleRef = useRef()
  const titleChars = useRef(null)

  useLayoutEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(DrawSVGPlugin, SplitText)
    }
    const mySplit = new SplitText(titleRef.current, {
      type: 'words, chars',
    })
    titleChars.current = mySplit.chars

    tl.current = gsap.timeline({
      defaults: {
        ease: 'expo.inOut',
      },
      onStart: () => document.body.classList.add('contain'),
      onComplete: () => document.body.classList.remove('contain'),
    })
    tl.current
      .set([SVGRef.current, homeHeroTitleRef.current], {
        autoAlpha: 0,
      })
      .set([circle1Ref.current, circle2Ref.current], { drawSVG: '0%' })
      .to(bgRef.current, {
        scaleY: 0,
        transformOrigin: 'top',
        delay: 1,
        duration: 2.6,
      })
      .from(
        imageContainerRef.current,
        {
          y: '50%',
          duration: 2.6,
        },
        '-=2.6'
      )
      .from(
        imageClipRef.current,
        {
          scale: 1.2,
          ease: 'none',
          duration: 3,
        },
        '-=1'
      )
      .to(
        imageClipRef.current,
        {
          '--mask-right': 24,
          duration: 6,
        },
        '-=2'
      )
      .to(
        imageClipRef.current,
        {
          '--mask-bottom': 24,
          duration: 6,
        },
        '-=6'
      )
      .to(
        imageClipRef.current,
        {
          '--mask-left': 60,
          duration: 6,
        },
        '-=6'
      )
      .set(
        SVGRef.current,
        {
          autoAlpha: 1,
        },
        '-=2'
      )
      .from(
        parentRectRef.current.children,
        {
          x: -200,
          autoAlpha: 0,
          scaleX: 0,
          duration: 2,
          stagger: {
            each: 0.1,
            // from: 'random',
            from: 'end',
          },
        },
        '-=2'
      )
      .to(rect2Ref.current, {
        skewX: 22.5,
        transformOrigin: 'left',
        duration: 2,
      })
      .to(
        rect4Ref.current,
        {
          skewX: 13,
          transformOrigin: 'left',
          duration: 2,
        },
        '-=2'
      )
      .to(
        rect5Ref.current,
        {
          skewX: -13,
          transformOrigin: 'left',
          duration: 2,
        },
        '-=2'
      )
      .to(
        rect6Ref.current,
        {
          skewX: 13,
          transformOrigin: 'left',
          duration: 2,
        },
        '-=2'
      )
      .to(
        rect7Ref.current,
        {
          skewX: -13,
          transformOrigin: 'left',
          duration: 2,
        },
        '-=2'
      )
      .to(
        rect8Ref.current,
        {
          skewX: 13,
          transformOrigin: 'left',
          duration: 2,
        },
        '-=2'
      )
      .to(
        rect9Ref.current,
        {
          skewX: -13,
          transformOrigin: 'left',
          duration: 2,
        },
        '-=2'
      )
      .to(
        rect10Ref.current,
        {
          skewX: 13,
          transformOrigin: 'left',
          duration: 2,
        },
        '-=2'
      )
      .to(
        circle1Ref.current,
        {
          drawSVG: '100%',
          duration: 2,
        },
        '-=2'
      )
      .to(
        circle2Ref.current,
        {
          drawSVG: '100%',
          duration: 2,
        },
        '-=2'
      )
      .set(homeHeroTitleRef.current, { autoAlpha: 1 }, '-=1.7')
      .from(
        titleChars.current,
        {
          duration: 1,
          opacity: 0,
          y: 60,
          stagger: 0.02,
        },
        '-=1.7'
      )
  }, [])

  return (
    <StyledSliderHeroContainer>
      <StyledBg ref={bgRef} />
      <StyledImageContainer ref={imageContainerRef}>
        <div className="image-container" ref={imageClipRef}>
          <GatsbyImage image={mainImage} alt={homepage.data.cover.alt} />
        </div>
      </StyledImageContainer>

      <StyledLogoContainer>
        <svg
          ref={SVGRef}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 590 110"
        >
          <g className="slashSVG" ref={parentRectRef}>
            <rect
              ref={rect1Ref}
              fill="#fff"
              y="2.6"
              width="13.1"
              height="104.8"
            />
            <rect
              ref={rect2Ref}
              fill="#fff"
              x="34.4"
              y="2.6"
              width="13.2"
              height="104.8"
            />
            <rect
              ref={rect3Ref}
              fill="#fff"
              x="69"
              y="2.6"
              width="13.1"
              height="104.8"
            />
            <rect
              ref={rect4Ref}
              fill="#fff"
              x="227.4"
              y="2.6"
              width="13.1"
              height="104.8"
            />
            <rect
              ref={rect5Ref}
              fill="#fff"
              x="264.3"
              y="2.6"
              width="13.1"
              height="104.8"
            />
            <rect
              ref={rect6Ref}
              fill="#fff"
              x="301.2"
              y="2.6"
              width="13.1"
              height="104.8"
            />
            <rect
              ref={rect7Ref}
              fill="#fff"
              x="338"
              y="2.6"
              width="13.1"
              height="104.8"
            />
            <rect
              ref={rect8Ref}
              fill="#fff"
              x="374.9"
              y="2.6"
              width="13.1"
              height="104.8"
            />
            <rect
              ref={rect9Ref}
              fill="#fff"
              x="411.8"
              y="2.6"
              width="13.1"
              height="104.8"
            />
            <rect
              ref={rect10Ref}
              fill="#fff"
              x="448.7"
              y="2.6"
              width="13.1"
              height="104.8"
            />
          </g>
          <circle
            ref={circle1Ref}
            fill="none"
            stroke="#fff"
            strokeWidth="12.2249"
            strokeMiterlimit="10"
            cx="534.9"
            cy="55"
            r="47.8"
          />
          <circle
            ref={circle2Ref}
            stroke="#fff"
            fill="none"
            strokeWidth="12.2249"
            strokeMiterlimit="10"
            cx="152.2"
            cy="55"
            r="47.8"
          />
        </svg>
        <div className="home-hero-title" ref={homeHeroTitleRef}>
          <h3 ref={titleRef}>{homepage.data.title.text}</h3>
        </div>
      </StyledLogoContainer>
    </StyledSliderHeroContainer>
  )
}

export default SliderHero
