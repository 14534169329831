import React, { useRef, useLayoutEffect } from 'react'
import styled from '@emotion/styled'
import { gsap } from 'gsap'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'

if (typeof window !== 'undefined') {
  gsap.registerPlugin(DrawSVGPlugin)
}

const StyledDescription = styled.header`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 40px 16px;
  min-height: 40vh;
  background: green;
  background: ${(props) => props.theme.colors.black};
  color: ${(props) => props.theme.colors.white};
  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    padding: 70px 16px;
    min-height: 60vh;
  }
  h2 {
    position: relative;
    max-width: ${(props) => props.theme.sizes.maxWidth};
    font-family: ${(props) => props.theme.fonts.grotta};
    color: ${(props) => props.theme.colors.white};
    line-height: 1.5;
    font-size: 24px;
    @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
      font-size: 48px;
      line-height: 1.21;
    }
  }
  .inlined-svg {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    .SVG-wrapper {
      position: absolute;
      display: block;
      height: auto;
      left: 0;
      top: 0;
    }
  }
  .atom {
    width: 32px;
    height: 32px;
    @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
      width: 44px;
      height: 44px;
    }
    svg {
      width: 32px;
      height: 32px;
      @media screen and (min-width: ${(props) =>
          props.theme.responsive.medium}) {
        width: 44px;
        height: 44px;
      }
    }
  }
  .infinite {
    width: 39px;
    height: 32px;
    @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
      width: 67px;
      height: 59px;
    }
    svg {
      width: 39px;
      height: 32px;
      @media screen and (min-width: ${(props) =>
          props.theme.responsive.medium}) {
        width: 67px;
        height: 59px;
      }
    }
  }
  .heart {
    width: 45px;
    height: 48px;
    transform: translateY(-8px);
    @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
      transform: translateY(-12px);
      width: 70px;
      height: 73px;
    }
    svg {
      width: 45px;
      height: 48px;
      @media screen and (min-width: ${(props) =>
          props.theme.responsive.medium}) {
        width: 70px;
        height: 73px;
      }
    }
  }
`

const Description = () => {
  const infinitePathRef = useRef()
  const atomElipseRef = useRef()
  const heartPathRef = useRef()

  useLayoutEffect(() => {
    gsap.fromTo(
      infinitePathRef.current,
      {
        drawSVG: '0% 80%',
      },
      {
        drawSVG: '100% 180%',
        ease: 'none',
        duration: 2,
        repeat: -1,
      }
    )
    gsap.fromTo(
      heartPathRef.current,
      {
        scale: 0.8,
        transformOrigin: 'center center',
      },
      {
        scale: 1,
        ease: 'back.inOut(1.7)',
        duration: 1,
        repeat: -1,
        yoyo: true,
      }
    )

    gsap.to(atomElipseRef.current, {
      duration: 3,
      rotation: 360,
      ease: 'none',
      repeat: -1,
    })
  }, [])

  return (
    <StyledDescription>
      <h2>
        <span>
          Nowmo is a design playground focus on beautiful stories with the best{' '}
          <span className="inlined-svg atom">
            <span className="SVG-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 51.0027 51.0027"
                ref={atomElipseRef}
              >
                <ellipse
                  cx="25.50135"
                  cy="25.50135"
                  rx="11.55894"
                  ry="25.69473"
                  transform="translate(-5.7313 7.46917) rotate(-15)"
                  fill="none"
                  stroke="#fff"
                  strokeMiterlimit="10"
                />
                <ellipse
                  cx="25.50135"
                  cy="25.50135"
                  rx="25.69473"
                  ry="11.55894"
                  transform="translate(-10.56301 25.50135) rotate(-45)"
                  fill="none"
                  stroke="#fff"
                  strokeMiterlimit="10"
                />
                <ellipse
                  cx="25.50135"
                  cy="25.50135"
                  rx="11.55894"
                  ry="25.69473"
                  transform="translate(-5.7313 43.53353) rotate(-75)"
                  fill="none"
                  stroke="#fff"
                  strokeMiterlimit="10"
                />
                <circle cx="25.50135" cy="25.50135" r="3.65795" fill="#fff" />
              </svg>
            </span>
          </span>{' '}
          technology. A place dedicated to{' '}
          <span className="inlined-svg infinite">
            <span className="SVG-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 67.26917 26.75287"
              >
                <path
                  d="M28.4485,18.39373c-4.01317,3.63591-8.57598,7.39069-14.19338,7.81663C6.78458,26.84872-.03324,20.20121,.53295,12.70019c.02384-.68509,.10916-1.36705,.25472-2.03711C1.8044,6.18041,5.19053,2.42657,9.60438,1.08717l-.00019,.00005c2.40731-.73342,4.97526-.75538,7.39508-.06337,3.99844,1.19697,7.43051,3.70399,10.50628,6.45769v-.00063c3.00509,2.90287,7.8572,7.69586,10.84969,10.499v.00063c4.34258,4.02081,9.49299,8.27444,15.72048,8.2402,8.16623-.02894,14.40143-8.1276,12.2728-16.02802-1.44849-6.10148-7.77164-10.40809-13.97753-9.59389-4.15912,.50964-7.81411,2.88996-10.98238,5.50403l-12.94012,12.29087Z"
                  fill="none"
                  stroke="#fff"
                  strokeMiterlimit="10"
                  ref={infinitePathRef}
                />
              </svg>
            </span>
          </span>{' '}
          motion and{' '}
          <span className="inlined-svg heart">
            <span className="SVG-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 59.8297 48.65169"
              >
                <path
                  ref={heartPathRef}
                  d="M29.00196,47.82461c.43611,.43611,1.30682,.43611,1.74294,0l13.72448-13.72448c3.92039-3.92039,3.48584-10.45606-1.52564-13.72448-3.48584-2.39635-8.27841-1.74294-11.32821,1.30682l-1.74294,1.96175-1.96023-2.17905c-3.92039-3.92039-10.45606-3.48583-13.72448,1.52564-2.39634,3.48584-1.74294,8.27841,1.30682,11.32821l13.50726,13.50558Z"
                  fill="none"
                  stroke="#fff"
                  strokeMiterlimit="10"
                />
              </svg>
            </span>
          </span>{' '}
          emotion.
        </span>
      </h2>
    </StyledDescription>
  )
}

export default Description
