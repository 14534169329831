import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'

import useMediaQuery from '../hooks/useMediaQuery'

import { gsap } from 'gsap'

const StyledWorkList = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  width: 100%;
  max-width: 1600px;
  justify-content: center;
  margin-bottom: 55px;
  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    justify-content: ${(props) => props.justifyContent};
    margin-bottom: 0;
  }
  .work__item {
    width: 90em;
    height: 122em;
    max-height: 516px;
    max-width: 380px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    will-change: transform;
    transform-style: preserve-3d;
    @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
      width: 42em;
      height: 57em;
      max-height: none;
      max-width: none;
    }
    .work__name {
      position: absolute;
      left: 0;
      top: auto;
      right: 0;
      bottom: 0;
      margin-right: auto;
      margin-left: auto;
      transform: translate(0px, 170%);
      font-family: ${(props) => props.theme.fonts.body};
      font-weight: 300;
      font-size: 14px;
      line-height: 1.2em;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: ${(props) => props.theme.colors.black};
      @media screen and (min-width: ${(props) =>
          props.theme.responsive.medium}) {
        font-size: 20px;
      }
    }
    a {
      position: relative;
      z-index: 20;
      display: flex;
      overflow: hidden;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      border-radius: 900px;
      max-width: 100%;
      color: #fff;
      text-decoration: none;
      background-color: transparent;
      .work__absolute {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        .work__image--wrapper {
          width: 100%;
          height: 100%;
          transition-property: transform;
          transition-duration: 800ms;
          transition-timing-function: ease;
        }
        .gatsby-image-wrapper {
          width: 100%;
          height: 100%;
        }
      }
      .work__overlay {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        transition-property: opacity;
        transition-duration: 800ms;
        transition-timing-function: ease;
      }
      .work__top-image {
        transition-property: opacity;
        transition-duration: 800ms;
        transition-timing-function: ease;
        width: 65em;
        max-width: 260px;
        position: relative;
        overflow: hidden;
        width: 100%;
        z-index: 20;
        border-radius: 0.32em;
        pointer-events: none;
        @media screen and (min-width: ${(props) =>
            props.theme.responsive.medium}) {
          max-width: 28em;
        }
      }
    }
  }
`

const ProjectCard = ({ project }) => {
  const [visible, setVisible] = useState(false)

  const cardRef = useRef()

  const isLargeScreen = useMediaQuery('(min-width: 40em)')

  useEffect(() => {
    isLargeScreen ? setVisible(false) : setVisible(true)
  }, [isLargeScreen])

  const handleMouseMove = (e) => {
    const bound = cardRef.current.getBoundingClientRect()
    const xVal = e.clientX - (bound.left + Math.floor(bound.width / 2))
    const yVal = e.clientY - (bound.top + Math.floor(bound.height / 2))

    gsap.to(cardRef.current, {
      x: xVal / 8,
      y: yVal / 8,
      ease: 'none',
    })
  }

  const handleMouseEnter = () => {
    // console.log("MouseEnter")
    setVisible(true)
  }

  const handleMouseLeave = (e) => {
    setVisible(false)
    gsap.to(cardRef.current, {
      x: 0,
      y: 0,
    })
  }

  return (
    <StyledWorkList justifyContent={project.data.classlist}>
      <div className="work__list">
        <div
          onMouseMove={handleMouseMove}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="work__item" ref={cardRef}>
            <Link to={`/project/${project.uid}`}>
              <div className="work__absolute">
                <div
                  className="work__image--wrapper"
                  style={{
                    height: '100%',
                    transform: visible ? 'scale(1)' : 'scale(1.03)',
                  }}
                >
                  <GatsbyImage
                    image={getImage(
                      project.data.workbackgroundimage.gatsbyImageData
                    )}
                    alt={project.data.workbackgroundimage.alt}
                  />
                </div>
              </div>
              <div
                className="work__overlay"
                style={{ opacity: visible ? 1 : 0 }}
              ></div>
              <div
                className="work__top-image"
                style={{ opacity: visible ? 1 : 0 }}
              >
                <GatsbyImage
                  image={getImage(project.data.worktopimage.gatsbyImageData)}
                  alt={project.data.worktopimage.alt}
                />
              </div>
            </Link>
            <div className="work__name">{project.data.title.text}</div>
          </div>
        </div>
      </div>
    </StyledWorkList>
  )
}

export default ProjectCard
