import { Canvas } from '@react-three/fiber'
import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/general/Layout'
import HeroText from '../components/index/HeroText'
import Hero from '../components/index/Hero'
import Box from '../components/index/Box'
// import Projects from "../components/index/Projects";
import Description from '../components/index/Description'
// import ProjectsParallax from '../components/index/ProjectsParallax'
import ScrollSmootherProject from '../components/index/ScrollSmootherProject'
import ProjectsTilt from '../components/index/ProjectsTilt'
import GetInTouch from '../components/general/GetInTouch'

const HomePage = ({ data }) => {
  const homepage = data.prismicHome
  const projects = data.allPrismicProject.edges
  return (
    <Layout>
      {/* <Canvas>
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        <Box position={[-1.2, 0, 0]} />
        <Box position={[1.2, 0, 0]} />
      </Canvas> */}
      <Hero homepage={homepage} />
      {/* <HeroText /> */}
      {/* <Description /> */}
      {/* <Projects projects={projects} /> */}
      {/* <ProjectsParallax projects={projects} /> */}
      {/* <ScrollSmootherProject projects={projects} /> */}
      {/* <ProjectsTilt projects={projects} /> */}
      {/* <GetInTouch /> */}
    </Layout>
  )
}

export const query = graphql`
  query homePageQuery($id: String, $lang: String) {
    prismicHome(id: { eq: $id }, lang: { eq: $lang }) {
      data {
        cover {
          gatsbyImageData(layout: FULL_WIDTH)
          alt
        }
        title {
          text
        }
      }
      lang
      type
      alternate_languages {
        type
        lang
      }
    }
    allPrismicProject(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          data {
            cover {
              fluid {
                src
              }
            }
            workbackgroundimage {
              alt
              gatsbyImageData(layout: FULL_WIDTH)
            }
            worktopimage {
              alt
              gatsbyImageData(layout: FULL_WIDTH)
            }
            horizontalimage {
              alt
              gatsbyImageData(layout: FULL_WIDTH)
              fluid {
                src
              }
            }
            color
            projectnumber
            title {
              text
            }
            classlist
          }
          id
          uid
          lang
        }
      }
    }
  }
`

export default HomePage
