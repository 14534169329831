import React, { useLayoutEffect, useRef } from 'react'
import styled from '@emotion/styled'
import ProjectCard from './ProjectCard'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

const StyledHeroSection = styled.div`
  position: relative;
  .sun {
    z-index: 10;
    position: absolute;
    left: 5vw;
    top: 0;
    right: auto;
    bottom: auto;
    width: 80%;
    height: 60vh;
    max-width: 540px;
    margin-top: 13vh;
    display: flex;
    align-items: flex-start;
    @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
      width: 27.5em;
      left: 58vw;
      margin-top: 19vh;
    }
    svg {
      position: relative;
      z-index: 3;
      // height: 300px;
      fill: ${(props) => props.theme.colors.black};
    }
    .work__text {
      p {
        color: ${(props) => props.theme.colors.black};
        font-family: ${(props) => props.theme.fonts.body};
        font-size: 20px;
        line-height: 1.5em;
        font-weight: 300;
        margin-left: 30px;
      }
    }
  }

  .work__info--absolute {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: auto;
    height: 120vh;
    z-index: 1;
    display: flex;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    padding: 8em 2em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .work__title {
      h2 {
        font-size: 14.8em;
        line-height: 0.9em;
        font-weight: 400;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        font-family: ${(props) => props.theme.fonts.grotta};
        white-space: nowrap;
        @media screen and (min-width: ${(props) =>
            props.theme.responsive.medium}) {
          font-size: 10em;
        }
      }
    }
  }
`

const StyledWorkSection = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding-top: 72vh;
  padding-right: 5em;
  padding-left: 5em;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    background-color: #e5e5e5;
    padding-bottom: 15vh;
    padding-top: 15vh;
  }
`

const ProjectsTilt = ({ projects }) => {
  const textTriggerRef = useRef()
  const textRef = useRef()
  const sunRef = useRef()

  useLayoutEffect(() => {
    const [x, xEnd] = [
      0,
      (textRef.current.scrollWidth - textTriggerRef.current.offsetWidth) * -1,
    ]

    gsap.fromTo(
      textRef.current,
      { x },
      {
        x: xEnd,
        scrollTrigger: {
          trigger: textTriggerRef.current,
          scrub: 0.5,
          start: 'top bottom',
          end: () => '+=' + textTriggerRef.current.offsetWidth * 8,
          // markers: true,
        },
      }
    )
  }, [])

  useLayoutEffect(() => {
    gsap.to(sunRef.current, {
      rotate: () => -360,
      ease: 'none',
      scrollTrigger: {
        trigger: textTriggerRef.current,
        start: 'top bottom',
        end: 'max',
        scrub: 1,
        // markers: true,
      },
    })
  }, [])
  return (
    <>
      <StyledHeroSection ref={textTriggerRef}>
        <div className="sun">
          <svg
            ref={sunRef}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 440 440"
          >
            <g>
              <path
                d="M440,211.9l-0.6-10.3l-208,15.9l200.9-56.3l-2.9-9.8L230.6,215l182.5-101.2l-5.1-8.9L229.3,212.6L383.6,72l-7-7.5
	L227.4,210.7L345.2,38.2l-8.5-5.6L225.2,209.2l74.9-195l-9.6-3.5l-67.8,197.6l28-207.1L240.5,0L220,208L199.5,0l-10.2,1.2l28,207.1
	L149.5,10.6l-9.6,3.5l74.9,195L103.3,32.6l-8.5,5.6l117.8,172.5L63.4,64.6l-7,7.5l154.3,140.6L32,104.9l-5.1,8.9L209.4,215
	L10.6,151.4l-2.9,9.8l200.9,56.3l-208-15.9L0,211.9l208.5,8.4L2.4,252.8l1.8,10.1l204.7-40l-193,79.3l4,9.4L210,225.3L40.4,347.1
	l6.1,8.2l165.1-127.8L74.6,385.1l7.8,6.6l131.2-162.5l-97,185l9.2,4.6L216,230.4l-51.8,202.4l10,2.4l44.4-204.1L214.9,440h10.2
	l-3.8-208.9l44.4,204.2l10-2.4L224,230.5l90.2,188.4l9.2-4.6l-97-185l131.2,162.5l7.8-6.6L228.4,227.5l165.1,127.8l6.1-8.2
	L230,225.3l190,86.2l4.1-9.4l-193-79.3l204.7,40l1.8-10.1l-206.1-32.6L440,211.9z"
              />
            </g>
          </svg>
          <div className="work__text">
            <p>
              Create your brand in motion. My collaboration with brands to
              captivate their audience, evoking emotion and creating the best
              solution. Have a look NOW.
            </p>
          </div>
        </div>
        <div className="work__info--absolute">
          <div className="work__title">
            <h2 ref={textRef}>Featured Work / Featured Work / Featured Work</h2>
          </div>
        </div>
      </StyledHeroSection>
      <StyledWorkSection>
        {projects &&
          projects.map(({ node: project }) => {
            return (
              <ProjectCard key={`project__${project.id}`} project={project} />
            )
          })}
      </StyledWorkSection>
    </>
  )
}

export default ProjectsTilt
